import {
  FormikType,
  HeaderLinkType,
  ListPaginationItem,
  ListSort,
  ListSortItem,
  SidebarMenuType,
  UseConstantsResult,
  UseFiltersResult,
  UseFormikHelperResult,
  UseListPaginationType,
  UseListSortType,
  UserStatusesType,
} from "system/helpers/types";
import { useEffect, useMemo, useState } from "react";
import isEqual from "lodash.isequal";
import { filterArrayByValues, getValues, parseNestedData, setMobileSort } from "./helperFunctions";
import { useTrans } from "../translations/hooks";
import { APP_ROUTES } from "../router/constants";
import { useHistory, useLocation } from "react-router-dom";
import { ReactComponent as DashboardIcon } from "images/icons/dashboard.svg";
import { ReactComponent as ActionLogIcon } from "images/icons/action_log.svg";
import { ReactComponent as TransactionsIcon } from "images/icons/transactions.svg";
import { ReactComponent as WalletIcon } from "images/icons/wallet.svg";
import { defaultSelectedPage, defaultPageSize } from "services/table/constants";
import { useToast } from "services/toast/hooks";
import {
  ApplicationIcon,
  CogIcon,
  AutomaticUpdatesIcon,
  HistoryIcon,
  PeopleIcon,
  PieChartIcon,
  SortAlphabeticalDescIcon,
  SortAlphabeticalIcon,
  SortIcon,
} from "evergreen-ui";
import wdNotification from "audio/wd_transaction.mp3";
import dpNotification from "audio/dp_transaction.mp3";
import { useDrawer } from "services/drawer/hooks";
import HeaderMobileFilters from "components/molecules/HeaderMobileFilters";
import Btn from "components/atoms/Btn";
import FormBuilder from "services/formBuilder/FormBuilder";
import { isEmpty } from "lodash";
import { FormConfigType } from "services/formBuilder/types";
import { isMobile } from "system/theme/globalStyles";

export const useFormikHelper = (formik: FormikType): UseFormikHelperResult => {
  const {
    errors,
    touched,
    status,
    setFieldTouched,
    setStatus,
    setFieldValue,
  }: FormikType = formik;
  const error = (name: string) =>
    (touched?.[name] && errors?.[name]) || (touched?.[name] && status?.[name]);
  const onChange = (name: string, value: any) => {
    setFieldValue(name, value);
    setStatus({ ...status, [name]: "" });
  };
  const onBlur = (name: string) => {
    setFieldTouched(name);
    setStatus({ ...status, [name]: "" });
  };
  return { error, onChange, onBlur };
};

export const useFilters = (
  initialFilters: any,
  flatObject: boolean = false,
  specialKeys: string[] = [],
  isMobile: boolean = false
): UseFiltersResult => {
  const [filtersValues, setFiltersNewFilters] = useState(initialFilters);
  const setFilters = (allFilters: any) => {
    let newFilters;

    if (isMobile && specialKeys.length) {
      const specialNewFilters = getValues(allFilters, specialKeys)
      const specialOldFilters = getValues(filtersValues, specialKeys)               
      newFilters = isEqual(specialNewFilters, specialOldFilters)
        ? isEqual(allFilters, filtersValues)
          ? filtersValues
          : allFilters
        : isEqual(allFilters, initialFilters)
          ? initialFilters
          : isEmpty(specialNewFilters)
            ? { ...allFilters, ...specialOldFilters }
            : { ...allFilters, ...specialNewFilters }       
    } else {
      newFilters = isEqual(allFilters, filtersValues)
        ? filtersValues
        : allFilters
    }
    

    setFiltersNewFilters(newFilters);
  };
  const filters = flatObject ? parseNestedData(filtersValues) : filtersValues;
  const filtersInArray = Object.values(filters).map((item, idx) => ({
    id: Object.keys(filters)[idx],
    value: item,
  }));
  return { filters, setFilters, filtersInArray };
};

export const useFEFilter = (data: any, filters: any) =>
  useMemo(() => filterArrayByValues(data, filters), [data, filters]);

const defaultInitialPagination: ListPaginationItem = {
  page: defaultSelectedPage,
  perPage: defaultPageSize,
};

const defaultInitialSort: ListSort = [] || {};

export const useListSort = (
  initialParams: ListSort = defaultInitialSort,
  singleFieldSort = true
): UseListSortType => {
  const [sort, setOnSort] = useState<ListSort>(initialParams);
  const setSort = (val: ListSortItem[]) => {
    if (val.length) {
      const sortFieldVal = {
        sortField: val[0].id,
        sortDir: val[0].desc ? "DESC" : "ASC",
      };
      const newSort = isEqual(sortFieldVal, sort) ? sort : sortFieldVal;
      setOnSort(newSort);
    } else {
      const newSort = isEqual({}, sort) ? sort : {};
      setOnSort(newSort);
    }
  };
  return { sort, setSort };
};

export const useListPagination = (
  initialParams: ListPaginationItem = defaultInitialPagination
): UseListPaginationType => {
  const [pagination, setPagin] = useState<ListPaginationItem>({
    ...initialParams,
  });
  const setPagination = (values: any) => {
    const page = values.page;
    const perPage = values.perPage;
    setPagin({ page, perPage });
  };

  return { pagination, setPagination };
};

const useTableChange = ({
  initialFilter = {},
  initialSort = {},
  initialPagination = {},
}: any) => {
  const { pagination, setPagination } = useListPagination(initialPagination);
  const { sort, setSort } = useListSort(initialSort);
  const { filters, setFilters, filtersInArray } = useFilters(initialFilter);

  return {
    pagination,
    setPagination,
    sort,
    setSort,
    filters,
    setFilters,
    filtersInArray,
  };
};

export const useConstants = (): UseConstantsResult => {
  const { _t } = useTrans();
  const userStatuses: UserStatusesType = [
    { value: true, label: _t("active") },
    { value: false, label: _t("deactivated") },
  ];
  const headerLinks: HeaderLinkType = [
    /*    { link: "#", title: _t("api_documentation") },
    { link: "#", title: _t("checkout_integration") },*/
  ];
  const sidebarMenu: SidebarMenuType = [
    {
      link: APP_ROUTES.dashboard.index,
      title: _t("dashboard"),
      icon: DashboardIcon,
      accessTo: "ROLE_VIEW_DASHBOARD",
      presentInMobileMenu: true,
    },
    {
      link: APP_ROUTES.transactions.index,
      title: _t("transactions"),
      icon: TransactionsIcon,
      accessTo: "ROLE_VIEW_TRANSACTIONS",
      presentInMobileMenu: true,
    },
    {
      link: APP_ROUTES.transactions.update,
      title: _t("change_transactions_status"),
      icon: AutomaticUpdatesIcon,
      accessTo: ["ROLE_SUPERADMIN", "ROLE_CHANGE_TRANSACTION_STATUS"],
    },
    {
      link: APP_ROUTES.users.index,
      title: _t("users_management"),
      icon: PeopleIcon,
      accessTo: "ROLE_MODIFY_USERS",
      presentInMobileMenu: true,
      mobileIcon: PeopleIcon,
    },
    {
      link: APP_ROUTES.merchants.index,
      title: _t("merchants"),
      icon: ApplicationIcon,
      accessTo: "ROLE_VIEW_MERCHANTS",
      presentInMobileMenu: true,
      mobileIcon: ApplicationIcon,
    },
    {
      link: APP_ROUTES.paymentMethods.index,
      title: _t("payment_methods"),
      icon: WalletIcon,
      accessTo: ["ROLE_PAYMENT_METHODS", "ROLE_MODIFY_FORM_TEMPLATES"],
      submenu: [
        {
          link: APP_ROUTES.payments.index,
          title: _t("methods"),
          accessTo: ["ROLE_PAYMENT_METHODS"],
        },
        {
          link: APP_ROUTES.paymentsFormTemplate.index,
          title: _t("form_template"),
          accessTo: ["ROLE_MODIFY_FORM_TEMPLATES"],
        },
      ],
    },
    {
      link: APP_ROUTES.statistics,
      title: _t("statistics"),
      icon: PieChartIcon,
      accessTo: "ROLE_VIEW_STATISTICS",
    },
    {
      link: APP_ROUTES.actionLog,
      title: _t("action_log"),
      icon: ActionLogIcon,
      accessTo: "ROLE_VIEW_ACTION_LOG",
    },
    {
      link: APP_ROUTES.historyLog,
      title: _t("history"),
      icon: HistoryIcon,
      accessTo: "ROLE_VIEW_HISTORY_LOG",
    },
    {
      link: "",
      title: _t("settings"),
      icon: CogIcon,
      accessTo: ["ROLE_MODIFY_SETTINGS", "ROLE_MODIFY_ROLES"],
      submenu: [
        {
          link: APP_ROUTES.translations.index,
          title: _t("translations"),
          accessTo: "ROLE_MODIFY_SETTINGS",
        },
        {
          link: APP_ROUTES.rejectionReasons.index,
          title: _t("rejection_reasons"),
          accessTo: "ROLE_MODIFY_SETTINGS",
        },
        /*    {
          link: APP_ROUTES.atmFees.index,
          title: _t("atm_fees"),
        },
        {
          link: APP_ROUTES.assignmentRules.index,
          title: _t("transaction_assignment"),
        },*/
        {
          link: APP_ROUTES.roles.index,
          title: _t("roles"),
          accessTo: "ROLE_MODIFY_ROLES",
        },
        {
          link: APP_ROUTES.currencies.index,
          title: _t("currencies"),
          accessTo: "ROLE_MODIFY_SETTINGS",
        } /*
        {
          link: APP_ROUTES.countries.index,
          title: _t("countries"),
        },*/,
      ],
    },
  ];
  return { userStatuses, sidebarMenu, headerLinks };
};

export const useOutsideClick = (ref: any) => {
  const [clickOutSide, setClick] = useState(false);
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setClick(true);
      } else {
        setClick(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return [clickOutSide];
};

export const useGetQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

export const useSuccessToast = () => {
  const toastHandler = useToast();
  const { _t } = useTrans();
  const defaultMessage = _t("success");
  const apiSuccessMessage = (
    text: string = defaultMessage,
    description?: string
  ): void => {
    const toastMessage = (msg: string, desc?: string) =>
      toastHandler.addToast({
        title: msg,
        description: desc,
        kind: "success",
      });
    toastMessage(text, description);
  };
  return { apiSuccessMessage };
};

export const useHandleWindowResize = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", () => setWindowSize(window.innerWidth));
  }, []);

  return windowSize;
};

export const useAudioNotification = () => {
  const playDPNotification = () => {
    const dpSound: any = new Audio(dpNotification);
    dpSound.play();
  };
  const playWDNotification = () => {
    const wdSound: any = new Audio(wdNotification);
    wdSound.play();
  };

  return { playDPNotification, playWDNotification };
};

export const useMobileFiltersDrawer = ({
  searchFormConfig, 
  filterInitialValues, 
  normalizeReqData = (data:any) => data,
  setFilters = () => {}
}: any) => {
  const { showDrawer, hideDrawer } = useDrawer();
  const { _t } = useTrans();
  const mobileModalSearchFormConfig = searchFormConfig.filter((searchItem: any) => !searchItem.openDrawer);
  const [filtersMobile, setFiltersMobile] = useState({})

  const onFilterResetMobile = () => {
    onFilterSubmitMobile({})
  }

  const onFilterSubmitMobile = async (data: any) => {
    const normalizeDate = normalizeReqData(data, filterInitialValues)    
    setFiltersMobile(normalizeDate);
    isEmpty(data)
      ? setFilters({})
      : setFilters(normalizeDate)
      hideDrawer('filters');
  };

  const showFilters = () => {
    showDrawer({
      isShowScale: false,
      header: HeaderMobileFilters,
      headerProps: {
        title: _t("filters"),
        onClose: () => hideDrawer('filters'),
      },
      componentProps: {
        showSubmit: false,
        showFiltersDrawer: true,
        formItemsConfig: mobileModalSearchFormConfig,
        formProps: {
          submitBtnLabel: _t("apply_filters"),
          onSubmit: onFilterSubmitMobile,
          initialValues: filterInitialValues,
          values: filtersMobile,
        },
      },
      component: FormBuilder,
      footer: Btn,
      footerProps: {
        kind: "custom",
        onClick: () => {
          onFilterResetMobile();
          hideDrawer('filters')
        },
        label: _t('reset'),
        style: {
          width: "48%",
          height: "40px"
        }
      }
    }, 'filters');
  }


  return { showFilters, filtersMobile, setFiltersMobile }
}

export const usePrepareForm = (
  searchFormConfig: FormConfigType,
  filterInitialValues: any,
  normalizeReqData: any = (data: any) => data
  ) => {
  const mobileSearchFormConfig = searchFormConfig.filter((searchItem: any) => searchItem.openDrawer);
  const specialKeys = mobileSearchFormConfig.map((x: any) => x.name) as string[]
  const { filters, setFilters } = useFilters(filterInitialValues, false, specialKeys, isMobile);

  const filterParams = useMemo(
    () => ({searchFormConfig, filterInitialValues, filters, setFilters, normalizeReqData }),
    [searchFormConfig, filterInitialValues, filters]
  ); 
  
  return { mobileSearchFormConfig, filterParams, filters, setFilters }
}

export const useGetSortMenuMobileItems = (
  defaultItemName: string,
  fieldName: string,
  setSort: (arg: any) => any
  ) => {
  const {_t} = useTrans()
  const sortMenuItems = [
    {
      title: _t("{sort_default_name}", {
        sort_default_name: defaultItemName,
      }),
      onClick: () => setMobileSort(fieldName, setSort),
      icon: SortIcon
    },
    {
      title: _t("sort_A_Z"),
      onClick: () => setMobileSort(fieldName, setSort, 'A-Z'),
      icon: SortAlphabeticalIcon
    },
    {
      title: _t("sort_Z_A"),
      onClick: () => setMobileSort(fieldName, setSort, 'Z-A'),
      icon: SortAlphabeticalDescIcon
    },
  ];

  return {sortMenuItems}
}
