import React from "react";
import FormBuilder from "services/formBuilder/FormBuilder";
import { useTrans } from "system/translations/hooks";
import { useUpdateStatusConfig } from "modules/transactions/constantHooks";
import { useUpdateTransactionStatus } from "modules/transactions/apiHooks";
import SectionTitle from "components/atoms/SectionTitle";
import { updateTransactionStatusValidation } from "../validation"
import globalStyles from "system/theme/globalStyles";

const UpdateTransactionStatus = () => {
  const { _t } = useTrans();
  const { mutate, isLoading } = useUpdateTransactionStatus();
  const formConfig = useUpdateStatusConfig({ isLoading });
  const onSubmit = (data: any) => {
    mutate(data);
  };

  return (
    <>
      <div css={globalStyles.mobileHeader}>
        <SectionTitle title={_t("change_transactions_status")} />
      </div>
      <FormBuilder
        showSubmit={false}
        formItemsConfig={formConfig}
        formProps={{
          onSubmit: onSubmit,
          validationSchema: updateTransactionStatusValidation(_t),
        }} />
    </>
  );
};

export default UpdateTransactionStatus;
