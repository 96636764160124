import {
  defaultPageSize,
  defaultSelectedPage,
} from "../../services/table/constants";

const config = {
  operationTypes: [
    {
      label: "3D Purchase",
      value: "3D",
    },
    {
      label: "Cancel",
      value: "CANCEL",
    },
    {
      label: "Stored Purchase",
      value: "STORED",
    },
    {
      label: "Direct Purchase",
      value: "DIRECT",
    },
    {
      label: "3D Auth",
      value: "3DAUTH",
    },
    {
      label: "Refund",
      value: "REFUND",
    },
  ],
  transactionTypes: [
    {
      label: "Deposit",
      value: "DEPOSIT",
    },
    {
      label: "Withdrawal",
      value: "WITHDRAWAL",
    },
  ],
  transactionStatuses: [
    {
      label: "Approved",
      value: "APPROVED",
    },
    {
      label: "Canceled",
      value: "CANCELED",
    },
    {
      label: "Declined",
      value: "DECLINED",
    },
    {
      label: "Waiting",
      value: "WAITING",
    },
    {
      label: "Pending",
      value: "PENDING",
    },
    {
      label: "Expired",
      value: "EXPIRED",
    },
    {
      label: "Error",
      value: "ERROR",
    },
  ],
  defaultInitialPagination: {
    page: defaultSelectedPage,
    perPage: defaultPageSize,
  },
  dateFormat: "yyyy-MM-dd",
};

export default config;
