export const LOGIN_URL = "/api/authenticate";
export const LOGOUT_URL = "/api/authenticate/logout";
export const LOGIN_TFA_URL = "/api/authenticate/tfa";
export const RESET_PASSWORD_URL = "/api/reset-password";
export const API_CHANGE_PASSWORD = "/api/global/password";

export const API_GET_CURRENT_ADMIN = "/api/users/currentUser";
export const API_GET_USERS = "/api/users";
export const API_GET_USER = "/api/users/:id";
export const API_GET_USERS_NAME_LIST = "/api/users/nameList";
export const API_GET_AGENTS_NAME_LIST = "/api/agents/nameList/users";
export const API_CREATE_USER = "/api/users";
export const API_UPDATE_USER = "/api/users";
export const API_ENABLE_USER_TFA = "/api/global/2fa";
export const API_USER_RESET_TFA = "/api/users/:id/resetTfa";
export const API_DELETE_USER = "/api/users/:id";
export const API_RESET_USER_PASSWORD = "/api/reset-password/force";
export const API_RESEND_EMAIL_CONFIRMATION_LINK = "/api/users/resend-link/:id";

export const API_GET_ROLES = "/api/roles/security-groups";
export const API_GET_AUTHORITIES = "/api/roles/authorities";
export const API_GET_ROLE = "/api/roles/security-groups/:id";
export const API_CREATE_ROLE = "/api/roles/security-groups";
export const API_UPDATE_ROLE = "/api/roles/security-groups/:id";
export const API_DELETE_ROLE = "/api/roles/security-groups/:id";

export const API_GET_MERCHANTS = "/api/merchants";
export const API_GET_SUB_MERCHANTS =
  "/api/merchants/:merchantId/sub?page=1&perPage=999";
export const API_GET_MERCHANT = "/api/merchants/:id";
export const API_GET_MERCHANTS_NAME_LIST = "/api/merchants/nameList";
export const API_CREATE_MERCHANT = "/api/merchants";
export const API_UPDATE_MERCHANT = "/api/merchants";
export const API_DELETE_MERCHANT = "/api/merchants/:id";
export const API_MERCHANT_PAYMENT_METHODS = "/api/merchants/:id/paymentMethods";
export const API_MERCHANT_GET_PAYMENT_GATEWAY_CODES =
  "/api/settings/paymentGWs";
export const API_GET_MERCHANT_BLACK_LIST =
  "/api/merchants/:merchantId/blacklist";
export const API_GET_MERCHANT_BLACK_LIST_BY_ID =
  "/api/merchants/:merchantId/blacklist/:id";
export const API_CREATE_MERCHANT_BLACK_LIST =
  "/api/merchants/:merchantId/blacklist/:email";
export const API_UPDATE_MERCHANT_BLACK_LIST =
  "/api/merchants/:merchantId/blacklist/:id";
export const API_DELETE_MERCHANT_BLACK_LIST =
  "/api/merchants/:merchantId/blacklist/:id";

export const API_GET_MERCHANT_WHITE_LIST =
  "/api/merchants/:merchantId/restricts?page=1&perPage=100";
export const API_CREATE_MERCHANT_WHITE_LIST =
  "/api/merchants/:merchantId/restricts";
export const API_GET_MERCHANT_WHITE_LIST_BY_ID =
  "/api/merchants/:merchantId/restricts/:id";
export const API_UPDATE_MERCHANT_WHITE_LIST =
  "/api/merchants/:merchantId/restricts/:id";
export const API_DELETE_MERCHANT_WHITE_LIST =
  "/api/merchants/:merchantId/restricts/:id";

export const API_GET_MERCHANT_PAYMENTS = "/api/merchants/paymentMethods";
export const API_CREATE_MERCHANT_PAYMENT = "/api/merchants/paymentMethods";
export const API_GET_MERCHANT_PAYMENT_BY_ID =
  "/api/merchants/paymentMethods/:id";
export const API_UPDATE_MERCHANT_PAYMENT = "/api/merchants/paymentMethods";
export const API_DELETE_MERCHANT_PAYMENT = "/api/merchants/paymentMethods/:id";

export const API_GET_METHOD_PAYMENT_DETAILS =
  "/api/merchants/credentials/:methodId";
export const API_GET_METHOD_PAYMENT_DETAILS_HISTORY =
  "/api/merchants/credentials/history/:methodId";
export const API_UPDATE_METHOD_PAYMENT_DETAILS = "/api/merchants/credentials";
export const API_DELETE_METHOD_PAYMENT_DETAIL =
  "/api/merchants/credentials/:id";

export const API_GET_TRANSLATIONS = "/api/translations";
export const API_GET_TRANSLATION = "/api/translations/:id";
export const API_CREATE_TRANSLATIONS = "/api/translations/:id";
export const API_UPDATE_TRANSLATIONS = "/api/translations/:id";
export const API_DELETE_TRANSLATIONS = "/api/translations/:id";
export const API_GET_TRANSLATIONS_LANGUAGES = "/api/translations/languages";

export const API_GET_CURRENCIES = "/api/currencies";
export const API_GET_CURRENCY = "/api/currencies/:id";
export const API_CREATE_CURRENCIES = "/api/currencies";
export const API_UPDATE_CURRENCIES = "/api/currencies";
export const API_DELETE_CURRENCIES = "/api/currencies/:id";

export const API_GET_COUNTRIES = "/api/countries";
export const API_GET_COUNTRY = "/api/countries/:id";
export const API_CREATE_COUNTRIES = "/api/countries";
export const API_UPDATE_COUNTRIES = "/api/countries";
export const API_DELETE_COUNTRIES = "/api/countries/:id";

export const API_GET_ATM_FEES = "/api/atmFees";
export const API_GET_ATM_FEE = "/api/atmFees/:id";
export const API_CREATE_ATM_FEES = "/api/atmFees";
export const API_UPDATE_ATM_FEES = "/api/atmFees";
export const API_DELETE_ATM_FEES = "/api/atmFees";

export const API_GET_ASSIGNMENT_RULES = "/api/countries";
export const API_GET_ASSIGNMENT_RULE = "/api/countries/:id";
export const API_CREATE_ASSIGNMENT_RULES = "/api/countries";
export const API_UPDATE_ASSIGNMENT_RULES = "/api/countries";
export const API_DELETE_ASSIGNMENT_RULES = "/api/countries";

export const API_GET_REJECTION_REASONS = "/api/rejection-reasons";
export const API_GET_REJECTION_REASON = "/api/rejection-reasons/:id";
export const API_CREATE_REJECTION_REASONS = "/api/rejection-reasons";
export const API_UPDATE_REJECTION_REASONS = "/api/rejection-reasons";
export const API_DELETE_REJECTION_REASONS = "/api/rejection-reasons";

export const API_GET_TRANSACTIONS = "/api/transactions";
export const API_UPDATE_TRANSACTION_STATUS =
  "/api/transactions/change-transaction-status";
export const API_UPDATE_TRANSACTION = "/api/transactions/updateTransaction/:id";
export const API_GET_TRANSACTIONS_EXPORT = "/api/transactions/export";
export const API_GET_TRANSACTION = "/api/transactions/:id";
export const API_GET_PAYMENT_METHODS_NAME_LIST = "/api/paymentMethods/nameList";
export const API_GET_PAYMENT_GATEWAYS = "api/transactions/gwList";
export const API_UPDATE_RESEND_IPN = "/api/notifications/resentIpn/:id";
export const API_ASSIGN_TRANSACTION = "/api/transactions/assign/:id";
export const API_RESET_ASSIGN_TRANSACTION = "/api/transactions/reset/:id";
export const API_RESEND_IPN = "/api/transactions/resendIpn/:id";

export const API_GET_CLIENT = "/api/clients/:id";
export const API_GET_ACTION_LOG = "/api/action-log";

export const API_GET_HISTORY_LOG = "/api/transactions/history/:transactionId";
export const API_GET_TRANSACTION_HISTORY =
  " /api/transactions/history/record/:transactionId";

export const API_GET_PAYMENTS = "/api/paymentMethods";
export const API_GET_PAYMENTS_NAME_LIST = "/api/paymentMethods/nameList";
export const API_GET_PAYMENT = "/api/paymentMethods/:id";
export const API_CREATE_PAYMENT = "/api/paymentMethods";
export const API_UPDATE_PAYMENT = "/api/paymentMethods";
export const API_DELETE_PAYMENT = "/api/paymentMethods/:id";

export const API_GET_FORM_TEMPLATES = "/api/formTemplates";
export const API_GET_FORM_TEMPLATE = "/api/formTemplates/:id";
export const API_CREATE_FORM_TEMPLATE = "/api/formTemplates";
export const API_UPDATE_FORM_TEMPLATE = "/api/formTemplates";
export const API_DELETE_FORM_TEMPLATE = "/api/formTemplates/:id";
