import { Link } from "carbon-components-react";
import InfoTag from "components/atoms/InfoTag";
import React, { useMemo } from "react";
import { useTrans } from "system/translations/hooks";
import { FormConfigType } from "../../services/formBuilder/types";
import config from "../../system/config";
import { ArrayOptionsMapRes } from "system/helpers/types";
import { filterOptions, transactionStatuses } from "./constants";
import { useDefaultFieldsConfig } from "services/formBuilder/hooks";
import { RoleWrapper } from "modules/admin/components/RoleWrapper";
import CopyButtonWrapper from "components/atoms/CopyButtonWrapper";
import {
  CrossIcon,
  InfoSignIcon,
  Pane,
  Popover,
  TickIcon,
  Text,
  Pill,
  HistoryIcon,
} from "evergreen-ui";
import themeOptions from "system/theme";
import Btn from "components/atoms/Btn";
import {
  useModuleData,
  useTransactionModuleData,
} from "modules/transactions/hooks";
import { css } from "@emotion/css";
import { css as cssReact } from "@emotion/react";
import { convertAmount, getNestedValue } from "system/helpers/helperFunctions";
import TransactionAssign from "modules/transactions/components/TransactionAssign";
import { TableColumnType } from "services/table/types";
import globalStyles, { isMobile } from "system/theme/globalStyles";
import ShowDataDetails from "components/molecules/ShowDataDetails";

export const useTableColumns = ({
  paymentDetailsKeys = {},
  isPendingTransactions,
}: any): TableColumnType[] => {
  const { _t } = useTrans();
  const cells = isPendingTransactions
    ? Object.keys(paymentDetailsKeys)?.map((item: any) => {
        return {
          Header: item,
          id: item,
          accessor: item,
          disableSortBy: true,
          Cell: (props: any) => {
            return !!props?.row?.original?.paymentDetails?.properties?.[item]
              ? props?.row?.original?.paymentDetails?.properties?.[item]
              : "-";
          },
        };
      })
    : [];
  const columns = useMemo(
    (): TableColumnType[] => [
      {
        Header: _t("merchant"),
        id: "merchant",
        accessor: "merchant.name",
        width: 140,
        disableSortBy: true,
        isMobileTitle: true,
      },
      {
        Header: _t("transaction_id"),
        id: "transactionUUID",
        accessor: "transactionUUID",
        minWidth: 150,
        disableSortBy: true,
        Cell: (props: any) => {
          return (
            <>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  gap: themeOptions.spacing.xs,
                }}
              >
                <RoleWrapper
                  defaultComponent={
                    <span>{props?.row?.original?.transactionUUID}</span>
                  }
                  accessTo={"ROLE_VIEW_TRANSACTION_DETAILS"}
                >
                  <Link
                    onClick={(e) => {
                      e.stopPropagation();
                      props.showTransactionDetails(
                        props.row.original,
                        "transaction"
                      );
                    }}
                  >
                    {props?.row?.original?.transactionUUID}
                  </Link>
                </RoleWrapper>
                <CopyButtonWrapper
                  copyValue={props?.row?.original?.transactionUUID}
                />
                <RoleWrapper accessTo={"ROLE_VIEW_HISTORY_LOG"}>
                  <HistoryIcon
                    color={themeOptions.colors.gray600}
                    cursor={"pointer"}
                    onClick={() =>
                      props?.showHistory &&
                      props?.showHistory(props?.row?.original?.transactionUUID)
                    }
                  />
                </RoleWrapper>
              </div>
            </>
          );
        },
      },
      {
        Header: _t("type"),
        accessor: "transactionType",
        id: "transactionType",
        width: 100,
        disableSortBy: true,
      },
      {
        Header: _t("payment_method"),
        accessor: "paymentMethod.code",
        id: "paymentMethod1",
        disableSortBy: true,
        width: 110,
      },
      {
        Header: _t("amount"),
        accessor: "amount",
        id: "amount1",
        width: 100,
        disableSortBy: true,
        Cell: (props: any) => (
          <>
            {`${convertAmount(props?.row?.original?.amount)} ${
              props?.row?.original?.currency?.codeLiteral
            }`}
          </>
        ),
      },
      {
        Header: _t("processed_amount"),
        accessor: "processedAmount",
        id: "processedAmount",
        width: 100,
        disableSortBy: true,
        Cell: (props: any) => (
          <>
            {`${convertAmount(props?.row?.original?.processedAmount)} ${
              props?.row?.original?.currency?.codeLiteral
            }`}
          </>
        ),
      },
      {
        Header: _t("status"),
        accessor: "status",
        id: "status1",
        width: 150,
        disableSortBy: true,
        Cell: (props: any) => (
          <Pane display={"flex"} gap={8} alignItems={"center"}>
            <InfoTag
              status={props?.row?.original?.transactionStatus?.toLowerCase()}
              label={props?.row?.original?.transactionStatus}
            />
            {props?.row?.original?.ipnsent && (
              <Pill
                className={css`
                  color: ${themeOptions.colors.white};
                  background: ${themeOptions.colors.blue400};
                `}
              >
                IPN
              </Pill>
            )}
          </Pane>
        ),
      },
      ...cells,
      {
        Header: _t("user_id"),
        accessor: "customer.id",
        id: "customer",
        disableSortBy: true,
        width: 80,
      },
      {
        Header: _t("merchant_customer_id"),
        accessor: "customer.merchantCustomerId",
        id: "customer.merchantCustomerId",
        disableSortBy: true,
        width: 80,
      },
      {
        Header: _t("request_data"),
        accessor: "createdDate",
        id: "createdDate",
        width: 160,
        disableSortBy: true,
      },
      {
        Header: _t("approve_decline"),
        id: "approve_decline",
        disableSortBy: true,
        width: 150,
        Cell: (props: any) => {
          const properties =
            props?.row?.original?.paymentDetails?.properties || {};
          const transactionStatus = props?.row?.original?.transactionStatus;
          const isPending = transactionStatus === "PENDING";
          return (
            <Pane gap={"10px"} display={"flex"}>
              <RoleWrapper accessTo={"ROLE_MANAGE_TRANSACTIONS"}>
                {isPending && (
                  <Btn
                    onClick={() =>
                      props?.onApproveWithConfirm({
                        id: props?.row?.original.transactionUUID,
                      })
                    }
                    hasIconOnly
                    kind={"custom"}
                    iconBefore={
                      <TickIcon color={themeOptions.colors.green400} />
                    }
                  />
                )}
                {isPending && (
                  <Btn
                    onClick={() =>
                      props?.onDeclineWithConfirm({
                        id: props?.row?.original.transactionUUID,
                      })
                    }
                    hasIconOnly
                    kind={"custom"}
                    iconBefore={
                      <CrossIcon color={themeOptions.colors.red500} />
                    }
                  />
                )}
              </RoleWrapper>
              <RoleWrapper accessTo={"ROLE_PAYMENT_DETAILS"}>
                <Popover
                  content={
                    <Pane
                      maxWidth={isMobile ? "90vw" : 400}
                      display={isMobile ? "flex" : "flex"}
                      gridTemplateColumns={"1fr 1fr"}
                      flexDirection={"column"}
                      margin="auto"
                      alignItems="self-start"
                      justifyContent="center"
                      padding={10}
                      gap={10}
                    >
                      {Object.keys(properties)?.map((key, idx) => {
                        return (
                          <>
                            <Pane maxWidth="100%" wordBreak="break-all">
                              <Text color={themeOptions.colors.gray700}>
                                {key}:
                              </Text>
                              <Text fontWeight={600}>{properties?.[key]}</Text>
                            </Pane>
                          </>
                        );
                      })}
                      {!Object.keys(properties)?.length && (
                        <Text color={themeOptions.colors.gray700}>
                          {_t("no_data")}
                        </Text>
                      )}
                    </Pane>
                  }
                >
                  <div>
                    <RoleWrapper accessTo={"ROLE_VIEW_TRANSACTION_DETAILS"}>
                      <Btn
                        hasIconOnly
                        kind={"custom"}
                        iconBefore={
                          <InfoSignIcon color={themeOptions.colors.gray700} />
                        }
                      />
                    </RoleWrapper>
                  </div>
                </Popover>
              </RoleWrapper>
            </Pane>
          );
        },
      },
      {
        Header: _t("transaction_details"),
        id: "transaction_details",
        disableSortBy: true,
        width: 150,
        Cell: (props: any) => {
          const transactionDetails =
            props?.row?.original?.transactionDetails || {};
          return (
            <Pane gap={"10px"} display={"flex"}>
              <RoleWrapper accessTo={"ROLE_PAYMENT_DETAILS"}>
                <Popover
                  content={
                    <Pane
                      maxWidth={isMobile ? "90vw" : 400}
                      display={isMobile ? "flex" : "grid"}
                      gridTemplateColumns={"1fr 1fr"}
                      flexDirection={"column"}
                      margin="auto"
                      alignItems="self-start"
                      justifyContent="center"
                      padding={10}
                      gap={10}
                    >
                      {Object.keys(transactionDetails)?.map((key, idx) => {
                        let value = transactionDetails?.[key];
                        if (key === "amount") {
                          value = convertAmount(value);
                        }
                        return (
                          <>
                            <Pane maxWidth="100%" wordBreak="break-all">
                              <Text color={themeOptions.colors.gray700}>
                                {key}:
                              </Text>
                              <Text fontWeight={600}>{value}</Text>
                            </Pane>
                          </>
                        );
                      })}
                      {!Object.keys(transactionDetails)?.length && (
                        <Text color={themeOptions.colors.gray700}>
                          {_t("no_data")}
                        </Text>
                      )}
                    </Pane>
                  }
                >
                  <div>
                    <RoleWrapper accessTo={"ROLE_VIEW_TRANSACTION_DETAILS"}>
                      <Btn
                        hasIconOnly
                        kind={"custom"}
                        iconBefore={
                          <InfoSignIcon color={themeOptions.colors.gray700} />
                        }
                      />
                    </RoleWrapper>
                  </div>
                </Popover>
              </RoleWrapper>
            </Pane>
          );
        },
      },
      {
        Header: _t("done_by"),
        accessor: "agent",
        id: "agent",
        disableSortBy: true,
        width: 190,
        Cell: (props: any) => {
          return <TransactionAssign transaction={props.row.original} />;
        },
      },
      {
        Header: _t("payment_details"),
        id: "payment_details",
        width: 220,
        disableSortBy: true,
        Cell: (props: any) => {
          const properties =
            props?.row?.original?.paymentDetails?.properties || {};
      
          const renderProperties = (isPreview = false) => {
            const propertyKeys = Object.keys(properties);
      
            if (propertyKeys.length === 0) {
              return (
                <Pane height="40px" lineHeight="40px">
                  {_t("no_data")}
                </Pane>
              );
            }
      
            return propertyKeys.map((key) => (
              <Pane
                maxWidth="100%"
                wordBreak="break-all"
                marginBottom={"5px"}
              >
                <Pane color={themeOptions.colors.gray700} marginBottom="3px">
                  {key}:
                </Pane>
                <Pane fontWeight={600} display={"flex"} alignItems={"center"} gap={8}>
                  {properties?.[key]}
                  {!isPreview && (
                    <CopyButtonWrapper copyValue={properties?.[key]} toastTitle={_t("{item}_copied", {
                      item: `${_t("payment_details")}: ${key}`
                    })} />
                  )}
                </Pane>
              </Pane>
            ));
          };
      
          const hasMultipleProperties = Object.keys(properties).length > 1;
      
          return (
            <RoleWrapper accessTo={"ROLE_PAYMENT_DETAILS"}>
              {hasMultipleProperties ? (
                <ShowDataDetails
                  additionalClass={cssReact("height: 40px;")}
                  isShowVerticalDots
                  data={<>{renderProperties()}</>}
                  dataPeview={<>{renderProperties(true)}</>}
                  title={_t("payment_details")}
                />
              ) : (
                renderProperties()
              )}
            </RoleWrapper>
          );
        },
      },
      {
        Header: _t("merchant_transaction_id"),
        accessor: "merchantTransactionId",
        id: "merchantTransactionId",
        disableSortBy: true,
        width: 130,
      },
      {
        Header: _t("processed_date"),
        accessor: "updatedDate",
        id: "updatedDate",
        width: 160,
        disableSortBy: true,
      },
      {
        Header: _t("client_name"),
        id: "client_name",
        width: 120,
        disableSortBy: true,
        Cell: (props: any) => {
          return (
            <RoleWrapper
              defaultComponent={
                <span>
                  {props.row?.original?.customer?.lastName}{" "}
                  {props.row?.original?.customer?.firstName}
                </span>
              }
              accessTo={"ROLE_VIEW_TRANSACTION_CLIENT"}
            >
              {/*        <Link
                onClick={() =>
                  props.showClientDetails(props.row.original, "customer")
                }
              >
                {props.row?.original?.customer?.firstName}{" "}
                {props.row?.original?.customer?.lastName}
              </Link>*/}
              <span>
                {props.row?.original?.customer?.firstName}{" "}
                {props.row?.original?.customer?.lastName}
              </span>
            </RoleWrapper>
          );
        },
      },
      {
        Header: _t("rejection_reason"),
        accessor: "rejectionReason.description",
        id: "rejectionReason",
        width: 150,
        disableSortBy: true,
      },
      {
        Header: _t("comment"),
        accessor: "comment",
        id: "comment",
        width: 120,
        disableSortBy: true,
      },
    ],
    [paymentDetailsKeys]
  );

  return columns;
};

export const useSearchFormConfig = ({
  isPendingTransactions,
}: {
  isPendingTransactions: boolean;
}): FormConfigType => {
  const { _t } = useTrans();
  const { merchantsOptions, paymentMethodsOptions } = useModuleData();
  const transactionTypes = config.transactionTypes;
  const filterByOptions: ArrayOptionsMapRes = useMemo(
    () => filterOptions(_t),
    [_t]
  );
  const statusesOptions = transactionStatuses(_t);
  const fields = useDefaultFieldsConfig();
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.filters.filterValue({
        openDrawer: true,
      }),
      fields.filters.filterField({
        openDrawer: true,
        componentProps: {
          options: filterByOptions,
        },
      }),
      fields.general.searchBtn({
        openDrawer: true,
        componentProps: {
          style: {
            color: "#8F95B2",
            borderColor: "#D8DAE5",
            backgroundColor: "#fff",
          },
        },
      }),
      fields.filters.date(),
      fields.filters.merchantId({
        componentProps: {
          options: merchantsOptions,
        },
      }),
      fields.filters.paymentMethod({
        componentProps: {
          options: paymentMethodsOptions,
        },
      }),
      fields.filters.transactionType({
        componentProps: {
          options: transactionTypes,
        },
      }),
      fields.filters.transactionStatus({
        componentProps: {
          options: statusesOptions,
        },
        hidden: isPendingTransactions,
      }),
      fields.general.submitBtn({
        componentProps: {
          css: isMobile && globalStyles.submitBtnForMobileFilters,
        },
      }),
    ];
  }, [merchantsOptions, paymentMethodsOptions]);
  return formConfig;
};

export const useUpdateTransactionConfig = ({
  showButtons = true,
  showApproveDecline = false,
  isLoading = false,
}): FormConfigType => {
  const { _t } = useTrans();
  const fields = useDefaultFieldsConfig();
  const { rejectionReasonsOptions } = useTransactionModuleData();

  let config = [
    fields.formFields.rejectionReasonId({
      componentProps: {
        options: rejectionReasonsOptions,
      },
    }),
    fields.formFields.rejectionReasonText(),
  ];

  if (showButtons) {
    config.push(
      fields.general.actionButtons({
        componentProps: {
          wrapClassName: css`
            justify-content: flex-end;
          `,
          isLoading: isLoading,
          onOkKind: "danger",
          onOkText: _t("decline"),
        },
        columnParams: {
          lg: 12,
          md: 12,
        },
      })
    );
  }
  const formConfig = useMemo((): FormConfigType => {
    return config;
  }, [isLoading, rejectionReasonsOptions]);
  return formConfig;
};

export const useUpdateStatusConfig = (params: any): FormConfigType => {
  const { _t } = useTrans();
  const fields = useDefaultFieldsConfig();
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.formFields.transactionUUID(),
      fields.formFields.transactionStatus({
        componentProps: {
          needErrorIcon: true
        }
      }),
      fields.formFields.comment(),
      fields.general.submitBtn({
        componentProps: { label: _t("submit"), isLoading: params.isLoading },
      }),
    ];
  }, [params.isLoading]);
  return formConfig;
};
