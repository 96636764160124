export const APP_ROUTES = {
  actionLog: "/action-log",
  login: "/login",
  verified: "/verified",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",
  payments: {
    index: "/payments",
    create: "/payments/create",
    update: "/payments/:id",
  },
  paymentsFormTemplate: {
    index: "/form-template",
    create: "/form-template/create",
    update: "/form-template/:id",
    duplicate: "/form-template-duplicate/:id",
  },
  merchants: {
    index: "/merchants/",
    create: "/merchants/create",
    update: "/merchants/:id",
    blacklist: {
      index: "/merchants/:merchantId/blacklist",
    },
    whitelist: {
      index: "/merchants/:merchantId/whitelist",
    },
    payment_methods: {
      index: "/merchants/:merchantId/methods",
      create: "/merchants/:merchantId/methods/create",
      update: "/merchants/:merchantId/methods/:id",
      payment_details: "/merchants/:merchantId/methods/:id/details",
    },
  },
  agents: {
    index: "/settings",
  },
  paymentMethods: {
    index: "/payment-methods",
  },
  roles: {
    index: "/roles",
    create: "/roles/create",
    update: "/roles/:id",
  },
  statistics: "/statistics",
  historyLog: "/history",
  transactions: {
    index: "/transactions",
    update: "/update-status",
  },
  dashboard: {
    index: "/",
  },
  translations: {
    index: "/translations",
    create: "/translations/create",
    update: "/translations/:id",
  },
  currencies: {
    index: "/currencies",
    create: "/currencies/create",
    update: "/currencies/:id",
  },
  countries: {
    index: "/countries",
    create: "/countries/create",
    update: "/countries/:id",
  },
  atmFees: {
    index: "/atmFees",
    create: "/atmFees/create",
    update: "/atmFees/:id",
  },
  assignmentRules: {
    index: "/assignment-rules",
    create: "/assignment-rules/create",
    update: "/assignment-rules/:id",
  },
  rejectionReasons: {
    index: "/rejection-reasons",
  },
  users: {
    index: "/users",
    create: "/users/create",
    update: "/users/:id",
  },
};
